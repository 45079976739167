import {
    ENTRY_PERMISSION_GUEST,
    ENTRY_PERMISSION_REGULAR,
    ENTRY_PERMISSION_INTERNATIONAL,
    ENTRY_PERMISSION_FENEX,
} from '~/constants/permissions';

export const PERMISSION_MAP = {
    'tln': ENTRY_PERMISSION_REGULAR,
    'tln_international': ENTRY_PERMISSION_INTERNATIONAL,
    'fenex': ENTRY_PERMISSION_FENEX
};

export type UserContentPermissions = {
    'tln': boolean;
    'tln_international': boolean;
    'fenex': boolean;
}

export type UserContentPermissionsTypes = keyof UserContentPermissions;

export function hasAccess(
    craftEntry: { allowedToView: string[] },
    permissions?: UserContentPermissions
) {
    // If no permissions are present on the Craft entry, everyone can view it
    if (!craftEntry || !Array.isArray(craftEntry.allowedToView)) {
        // console.log('1: no craft entry || no allowedToView options');
        return true;
    }

    // If all permissions are 'unchecked' on the Craft entry, nobody can see
    if (craftEntry.allowedToView.length === 0) {
        // console.log('2: no checkmarks have been set on the craft entry');
        return false;
    }

    // If the entry is public, everyone can view it
    if (craftEntry.allowedToView.includes(ENTRY_PERMISSION_GUEST)) {
        // console.log('3: guest permission is enabled');
        return true;
    }

    // If no user object is present, and the entry does have permissions set
    // which are not GUEST, the logged out user does not have permission
    if (!permissions) {
        return false;
    }

    let hasPermission = false;

    // Check if any of the user's permissions is present on the entry's permissions
    Object.keys(permissions).forEach((key) => {
        if (permissions[key as UserContentPermissionsTypes]) {
            const mappedPermission = PERMISSION_MAP[key as UserContentPermissionsTypes];

            if (craftEntry.allowedToView.includes(mappedPermission)) {
                hasPermission = true;
            }
        }
    });

    return hasPermission;
}

export function isAccessibleByGuests(
    craftEntry: { allowedToView: string[] }
) {
    if (!craftEntry || !craftEntry || !craftEntry.allowedToView || craftEntry.allowedToView.length > 0) {
        return false;
    }

    return craftEntry.allowedToView.includes(ENTRY_PERMISSION_GUEST);
}

export function isAccessibleByRegularMembership(
    craftEntry: { allowedToView: string[] }
) {
    if (!craftEntry || !craftEntry || !craftEntry.allowedToView || craftEntry.allowedToView.length > 0) {
        return false;
    }

    return craftEntry.allowedToView.includes(ENTRY_PERMISSION_REGULAR);
}

export function isInternationalOnly(
    craftEntry: { allowedToView: string[] }
) {
    if (!craftEntry || !craftEntry || !craftEntry.allowedToView || isAccessibleByGuests(craftEntry) || isAccessibleByRegularMembership(craftEntry)) {
        return false;
    }

    return craftEntry.allowedToView.includes(ENTRY_PERMISSION_INTERNATIONAL);
}

export function isFenexOnly(
    craftEntry: { allowedToView: string[] }
) {
    if (!craftEntry || !craftEntry || !craftEntry.allowedToView || isAccessibleByGuests(craftEntry) || isAccessibleByRegularMembership(craftEntry)) {
        return false;
    }

    return craftEntry.allowedToView.includes(ENTRY_PERMISSION_FENEX);
}
